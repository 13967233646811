import React from 'react'
import '../scss/global.scss'


export default function SectionIntro({children}) {

    return (
        <div className="section-intro centered">
            {children}
        </div>

    )
}